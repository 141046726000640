import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, addNewItem, changeDeletionMark, resetState, deleteItem } from '../service/actions'

import BasicObject from '@/dto/ProductionDemand.json'
const apiUrl = 'production_demand'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = {
  ...commonMutations,
  setConfigProductList(state: any, payload: any) {
    state.configProductList = payload
  },
}

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async getSelectFields() {
    return [{ key: 'name', label: 'table.name', sortable: true }]
  },

  async getConfigProducts(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, {
      noCommit: true,
      params: {
        filter: {
          configProduct: true,
        },
        sort: { sortBy: 'sortNumber', sortDesc: false },
        lang: payload.lang,
      },
    }).then((response) => {
      const products = response.data

      for (const product of products) {
        if (product.imgUrl) {
          try {
            product.img = require(`@/assets/images/products/${product.imgUrl}`)
          } catch (err) {
            product.img = require(`@/assets/images/products/noimg.png`)
          }
        } else {
          product.img = require(`@/assets/images/products/noimg.png`)
        }
      }
      if (!payload.noCommit) {
        context.commit('setConfigProductList', products)
      }
      return products
    })
  },

  async getPrice(context: ActionContext<IState, any>, payload: any) {
    return axios.post(`${apiUrl}/getPrice`, payload).then((response) => {
      return response.data
    })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY hh:mm:ss')
}

export const getters = {
  ...commonGetters,

  configProductList(state: any) {
    return state.configProductList
  },
}
