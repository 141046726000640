<template>
  <b-input-group v-if="hyperLink !== true" class="flex-nowrap">
    <v-select
      ref="fromSelectRef"
      :id="id"
      v-model="localValue"
      :options="options"
      :loading="false"
      :clearable="true"
      append-to-body
      class="with-append"
      :class="{ 'is-valid': required && valueState, 'is-invalid': required && !valueState }"
      :getOptionLabel="(el) => el[label]"
      :disabled="multiType && !valueType ? true : disabled"
      :multiple="multiple"
      :placeholder="placeholder"
      @search="onSearchValue"
      @contextmenu.prevent.native="onContextMenu"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching && loading === false">
          <em style="opacity: 0.5">
            {{ $t('msg.noFoundResults') }} <strong>{{ search }}</strong></em
          >.
        </template>
        <template v-else-if="searching && loading === true">
          <em style="opacity: 0.5">
            {{ $t('msg.searchResultsFor') }} <strong>{{ search }}</strong></em
          >.
        </template>
        <em v-else style="opacity: 0.5"> {{ $t('msg.startTypingForSearch') }} </em>
      </template>
      <template v-if="addBtn" #list-footer>
        <div class="d-flex justify-content-end">
          <a href="javascript:void(0);" style="text-align: right" class="btn-sm mr-2" @click="onAddNew"
            ><i class="ri-add-line"></i> {{ $tc('commands.addNew') }}</a
          >
        </div>
      </template>
    </v-select>
    <template v-slot:append>
      <template v-if="multiType">
        <b-button
          :variant="required ? (typeState ? 'outline-success' : 'outline-danger') : 'outline-secondary fs-button'"
          :state="typeState"
          class="f-select-btn"
          size="sm"
          :disabled="disabled"
          @click="openTypeSelectView"
        >
          <i class="ri-stack-line"></i>
        </b-button>
      </template>
      <template v-if="selectBtn">
        <b-button
          id="f-select-search"
          :variant="required ? (valueState ? 'outline-success' : 'outline-danger') : 'outline-secondary fs-button'"
          :state="valueState"
          class="f-select-btn"
          size="sm"
          :disabled="multiType && !valueType ? true : disabled"
          @click="openSelectView"
        >
          <i class="ri-list-check"></i>
        </b-button>
      </template>
      <template v-if="openBtn">
        <b-button
          id="f-select-open"
          :variant="required ? (valueState ? 'outline-success' : 'outline-danger') : 'outline-secondary fs-button'"
          :state="valueState"
          class="f-select-btn"
          size="sm"
          :disabled="multiType && !valueType ? true : disabled"
          @click="openDetailView"
        >
          <i class="ri-search-line"></i>
        </b-button>
      </template>
      <slot></slot>
    </template>
    <SelectView
      v-if="valueSelectMode"
      :value-type="valueType"
      @value-selected="valueSelectedEnd"
      :label="label"
      :filter="filter"
      :add-btn="addBtn"
      :detail-view="detailView"
      :advanced-filter="advancedFilter"
      :advanced-filter-static="advancedFilterStatic"
    />
    <SelectTypeView :visible="typeSelectMode" :items="valueTypes" @selected="typeSelectedEnd" />
    <ul :id="`select-context-${id}`" v-show="contextMenu.visible" :style="{ left: contextMenu.left + 'px', top: contextMenu.top + 'px' }" class="contextmenu">
      <li @click="copyToClipboard"><i class="ri-file-copy-line mr-1"></i> {{ $t('commands.copy') }}</li>
    </ul>
  </b-input-group>
  <div v-else class="col-form-label">
    <b-link href="javascript:void(0);" :disabled="multiType && !valueType ? true : disabled" @click="openDetailView"> {{ value ? value[label] : '' }} </b-link>
  </div>
</template>

<script>
import SelectView from '@/components/common/select-view'
import SelectTypeView from '@/components/common/select-type-view'
import { uuid } from 'vue-uuid'

export default {
  name: 'SelectDlg',

  components: {
    SelectView,
    SelectTypeView,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array],
      default: null,
    },
    valueType: {
      type: String,
      default: null,
      require: true,
    },
    label: {
      type: String,
      default: 'presentation',
    },
    placeholder: {
      type: String,
      default: null,
    },
    detailView: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectBtn: {
      type: Boolean,
      default: false,
    },
    openBtn: {
      type: Boolean,
      default: false,
    },
    addBtn: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: [Object, Promise],
      default: null,
    },
    multiType: {
      type: Boolean,
      default: false,
    },
    valueTypes: {
      type: Array,
      default: () => [],
    },
    hyperLink: {
      type: Boolean,
      default: false,
    },
    advancedFilter: {
      type: Boolean,
      default: false,
    },
    advancedFilterStatic: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: [],
      valueSelectMode: false,
      typeSelectMode: false,
      loading: false,
      sortBy: 'createdAt',
      contextMenu: {
        visible: false,
        left: 0,
        top: 0,
      },
    }
  },
  async created() {
    if (this.filter?.constructor?.name === 'Promise') {
      this.filter = await this.filter
    }
  },

  computed: {
    valueState() {
      return this.localValue !== null
    },

    typeState() {
      return this.valuetype !== null
    },

    localValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value) {
          const currOption = this.options.find((el) => el.id === value.id)

          if (currOption) {
            this.options = [{ ...currOption }]
          }
        } else {
          this.options = []
        }
        this.$emit('input', value)
        this.$emit('change', value)
      },
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (!newValue && this.options.length > 0) {
        this.options = []
      }
    },
    'contextMenu.visible'(newValue, oldValue) {
      if (newValue) {
        document.body.addEventListener('click', this.closeContextMenu)
      } else {
        document.body.removeEventListener('click', this.closeContextMenu)
      }
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.initOptions()
    },

    async initOptions() {
      if (this.localValue) {
        this.options.push({ ...this.localValue })

        if (this.label !== 'presentation') {
          if (this.localValue?.[this.label]) {
            this.sortBy = this.label
          }
        } else {
          if (this.localValue?.name) {
            this.sortBy = 'name'
          }
        }
      }
    },

    async onSearchValue(text) {
      if (text === '') {
        return
      }

      const reqParams = {
        params: {
          sort: { sortBy: this.sortBy, sortDesc: false },
          limit: 50,
          filter: {
            markedToDelete: false,
            searchStr: text,
          },
        },
      }

      if (this.filter) {
        for (const filterProperty in this.filter) {
          reqParams.params.filter[filterProperty] = this.filter[filterProperty]
        }
      }

      this.loading = true
      const response = await this.$store.dispatch(`${this.valueType}/findAll`, {
        ...reqParams,
        noCommit: true,
      })

      if (response.status === 200) {
        this.options = response.data
      } else {
        this.options = []
      }

      if (this.localValue) {
        const existOption = this.options.find((el) => el.id === this.localValue.id)
        if (!existOption) {
          this.options.push({ ...this.localValue })
        }
      }

      this.loading = false
    },

    copyToClipboard() {
      if (this.localValue) {
        navigator.clipboard.writeText(this.localValue[this.label])
      }
    },

    onContextMenu(event) {
      const pageHeigth = window.innerHeight
      const pageWidth = window.innerWidth
      this.contextMenu.visible = false

      this.contextMenu.visible = true
      this.contextMenu.left = event.x + 300 > pageWidth ? event.x - 300 : event.x
      this.contextMenu.top = event.y + 50 > pageHeigth ? event.y - 50 : event.y
    },

    closeContextMenu() {
      this.contextMenu.visible = false
    },

    async openSelectView() {
      this.valueSelectMode = true
    },

    async onAddNew() {
      let detailViewPath = this.detailView

      if (!detailViewPath) {
        const viewResponse = await this.$store.dispatch(`viewSettings/findAll`, {
          noCommit: true,
          params: {
            filter: {
              viewType: 'detail',
              // isDefault: true,
              quick: { 'appObject.name': this.valueType },
            },
          },
        })

        if (viewResponse && viewResponse.data?.length > 0) {
          const viewId = viewResponse.data[0].id

          if (viewId) {
            const navResponse = await this.$store.dispatch(`navigation/findAll`, {
              noCommit: true,
              params: {
                filter: {
                  viewId,
                  viewType: 'detail',
                },
              },
            })

            if (navResponse && navResponse.data?.length > 0) {
              detailViewPath = navResponse.data[0].name
            }
          }
        }
      }

      if (detailViewPath) {
        const id = uuid.v4()
        await this.$store.dispatch(`${this.valueType}/addNew`, id)
        this.$router.push({ name: detailViewPath, params: { id } })
      }
    },

    async openTypeSelectView() {
      const objNames = this.valueTypes.map((obj) => obj.valueType)
      await this.$store
        .dispatch('appObjects/findAll', {
          noCommit: true,
          params: {
            filter: {
              name: objNames,
            },
          },
        })
        .then((response) => {
          if (response.status === 200) {
            for (const type of this.valueTypes) {
              for (const object of response.data) {
                if (type.valueType === object.name) {
                  type.label = object.singularTitle
                  break
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
      this.typeSelectMode = true
    },

    async openDetailView() {
      if (!this.localValue) {
        return
      }

      let detailViewPath = this.detailView

      if (!detailViewPath) {
        detailViewPath = await this.getDetailView(this.valueType)
      }

      if (detailViewPath) {
        await this.$store
          .dispatch(`${this.valueType}/findByPk`, {
            params: {
              id: this.localValue.id,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.$router.push({ name: detailViewPath, params: { id: this.localValue.id } })
            }
          })
      }
    },

    async getDetailView(ref) {
      let detailPath = null

      const defineView = this.$store._actions[`${ref}/defineView`]

      if (defineView) {
        detailPath = this.$store.dispatch(`${ref}/defineView`, this.localValue)
      }

      if (!detailPath) {
        const viewResponse = await this.$store.dispatch(`viewSettings/findAll`, {
          noCommit: true,
          params: {
            filter: {
              viewType: 'detail',
              isDefault: true,
              quick: { 'appObject.name': ref },
            },
          },
        })

        if (viewResponse && viewResponse.data?.length > 0) {
          const viewId = viewResponse.data[0].id

          if (viewId) {
            const navResponse = await this.$store.dispatch(`navigation/findAll`, {
              noCommit: true,
              params: {
                filter: {
                  viewId,
                  viewType: 'detail',
                },
              },
            })

            if (navResponse && navResponse.data?.length > 0) {
              detailPath = navResponse.data[0].name
            }
          }
        }
      }

      return detailPath
    },

    typeSelectedEnd(value) {
      this.typeSelectMode = false
      this.$emit('update-value-type', value)
    },
    valueSelectedEnd(value) {
      if (value !== undefined) {
        const existOption = this.options.find((el) => el.id === value.id)
        if (!existOption) {
          this.options.push({ ...value })
        }
        this.$emit('input', value)
        this.$emit('change', value)
      }

      this.valueSelectMode = false
    },
  },
}
</script>

<style>
.f-select-btn {
  padding: 0 0.5rem !important;
}

.fs-button {
  border-color: #adb5bd;
}

.vs__dropdown-toggle,
.vs__dropdown-toggle,
.vs__dropdown-menu {
  border-color: #adb5bd;
  z-index: 1050 !important;
}
</style>

<style lang="scss" scoped>
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 5030;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  width: 300px;
  border-radius: 2px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}
</style>